<template>
  <competition v-if="active == 'competition'" @toWorks="toWorks" />
  <works v-if="active == 'works'" ref="worksRef" @back="active = 'competition'" />
</template>

<script>
import { ref, nextTick } from "vue";
import competition from "./competition/index.vue";
import works from "./competition/works.vue";
export default {
  components: {
    competition,
    works,
  },
  setup() {
    const active = ref("competition");
    const worksRef = ref(null);

    const toWorks = (item) => {
      active.value = "works";
      nextTick(() => {
        worksRef.value.competitionData = item;
        worksRef.value.competitionId = item.competitionId;
        worksRef.value.getList();
      });
    };

    return {
      active,
      worksRef,
      toWorks,
    };
  },
};
</script>
